import Head from 'next/head';
import Header from '@/components/header';
import Footer from '@/components/footer';

export default function MainLayout({
  children,
  title = 'Христианская церковь Благодать Белгород-Днестровский',
}) {
  return (
    <div id="wrap" className="boxed ">
      <div className="grey-bg layout-wrapper">
        <Head>
          <title>{title}</title>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <meta
            name="description"
            content="Мы рады приветствовать Вас на сайте Церкви Христиан Веры Евангельской Благодать город Белгород-Днестровский"
          />
          <meta
            name="keywords"
            content="церковь, благодать, Белгород-Днестровский"
          />
        </Head>
        <Header />
        {children}
        <Footer />
      </div>
      <div id="modalElement" />
    </div>
  );
}
