export const createMenuItems = () => [
  { title: 'Головна', url: '/' },
  {
    title: 'Про церкву',
    url: '/about',
    sub: [
      { title: 'Про нас', url: '/about/church' },
      // {
      //   title: 'Служители',
      //   url: '/about/ministers',
      // },
      { title: 'Розклад Богослужінь', url: '/about/schedule' },
      { title: 'Віровчення', url: '/about/doctrine' },
    ],
  },
  // {
  //   title: 'Медіа',
  //   url: '/media',
  //   sub: [
  //     {
  //       title: 'Служіння',
  //       url: '/media/ministries',
  //     },
  //     { title: 'Семінари', url: '/media/seminars' },
  //     { title: 'Пісні', url: '/media/songs' },
  //     {
  //       title: 'Відеоролики',
  //       url: '/media/videos',
  //     },
  //     {
  //       title: 'Постановки',
  //       url: '/media/scenes',
  //     },
  //     {
  //       title: 'Діти Благодаті',
  //       url: '/media/children',
  //     },
  //     {
  //       title: '5 питань',
  //       url: '/media/questions',
  //     },
  //     {
  //       title: 'Свідоцтва',
  //       url: '/media/testimonies',
  //     },
  //   ],
  // },
  // {
  //   title: 'Служіння',
  //   url: '/ministry',
  //   sub: [
  //     { title: 'Хвала и поклоніння', url: '/ministry/worship' },
  //     { title: 'Служба порядку', url: '/ministry/order' },
  //     { title: 'Молодіжне', url: '/ministry/youth' },
  //     { title: 'Дитяче', url: '/ministry/children' },
  //     { title: 'Домашні групи', url: '/ministry/home' },
  //   ],
  // },
  { title: 'Пожертвування', url: '/donation' },
  { title: 'Онлайн трансляції', url: 'https://www.youtube.com/@GraceChurchAkkerman/streams' },
  { title: 'Контакти', url: '/contacts' },
];

export const createSlides = () => [
  {
    id: 'slide-1',
    image: '/images/slides/ukr-logo.jpg',
  },
  // {
  //   id: 'slide-2',
  //   image: '/images/slides/fifth_slide.jpg',
  // },
  // {
  //   id: 'slide-3',
  //   image: '/images/slides/third_slide.jpg',
  // },
];

export const headings = {
  videos: 'Відеоролики',
  seminars: 'Семінари',
  songs: 'Пісні',
  ministries: 'Служіння',
  scenes: 'Постановки',
  children: 'Діти Благодаті',
  questions: '5 питань',
  testimonies: 'Свідоцтва',
};

const videos = [
  {
    category: 'seminars',
    _id: '603fbe21ad738808d093b966',
    url: 'https://youtu.be/u8FIFycIbtA',
    published_at: '2021-03-03T16:49:51.006Z',
    createdAt: '2021-03-03T16:49:37.874Z',
    updatedAt: '2021-03-15T18:39:51.858Z',
    __v: 0,
    poster: {
      _id: '603fbe19ad738808d093b965',
      name: 'poster15.jpg',
      alternativeText: '',
      caption: '',
      hash: 'poster15_ad5dad1d6a',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 28.19,
      width: 1280,
      height: 720,
      url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614790165/poster15_ad5dad1d6a.jpg',
      provider_metadata: {
        public_id: 'poster15_ad5dad1d6a',
        resource_type: 'image',
      },
      formats: {
        thumbnail: {
          name: 'thumbnail_poster15.jpg',
          hash: 'thumbnail_poster15_ad5dad1d6a',
          ext: '.jpg',
          mime: 'image/jpeg',
          width: 245,
          height: 138,
          size: 4.74,
          path: null,
          url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614790166/thumbnail_poster15_ad5dad1d6a.jpg',
          provider_metadata: {
            public_id: 'thumbnail_poster15_ad5dad1d6a',
            resource_type: 'image',
          },
        },
        large: {
          name: 'large_poster15.jpg',
          hash: 'large_poster15_ad5dad1d6a',
          ext: '.jpg',
          mime: 'image/jpeg',
          width: 1000,
          height: 563,
          size: 32,
          path: null,
          url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614790167/large_poster15_ad5dad1d6a.jpg',
          provider_metadata: {
            public_id: 'large_poster15_ad5dad1d6a',
            resource_type: 'image',
          },
        },
        medium: {
          name: 'medium_poster15.jpg',
          hash: 'medium_poster15_ad5dad1d6a',
          ext: '.jpg',
          mime: 'image/jpeg',
          width: 750,
          height: 422,
          size: 21.56,
          path: null,
          url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614790168/medium_poster15_ad5dad1d6a.jpg',
          provider_metadata: {
            public_id: 'medium_poster15_ad5dad1d6a',
            resource_type: 'image',
          },
        },
        small: {
          name: 'small_poster15.jpg',
          hash: 'small_poster15_ad5dad1d6a',
          ext: '.jpg',
          mime: 'image/jpeg',
          width: 500,
          height: 281,
          size: 12.65,
          path: null,
          url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614790169/small_poster15_ad5dad1d6a.jpg',
          provider_metadata: {
            public_id: 'small_poster15_ad5dad1d6a',
            resource_type: 'image',
          },
        },
      },
      provider: 'cloudinary',
      related: ['603fbe21ad738808d093b966'],
      createdAt: '2021-03-03T16:49:29.393Z',
      updatedAt: '2021-03-03T16:49:37.937Z',
      __v: 0,
      id: '603fbe19ad738808d093b965',
    },
    Date: '2021-02-18',
    title: 'Богдан Кравчук - Сила Божьего Слова и важность молитвы',
    id: '603fbe21ad738808d093b966',
  },
  {
    category: 'seminars',
    _id: '603fbe81ad738808d093b969',
    url: 'https://youtu.be/YV8d6y02LYc',
    published_at: '2021-03-03T16:51:23.398Z',
    createdAt: '2021-03-03T16:51:13.300Z',
    updatedAt: '2021-03-15T18:40:37.245Z',
    __v: 0,
    poster: {
      _id: '603fbe78ad738808d093b968',
      name: 'poster16.jpg',
      alternativeText: '',
      caption: '',
      hash: 'poster16_de471a33ef',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 36.6,
      width: 1280,
      height: 720,
      url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614790260/poster16_de471a33ef.jpg',
      provider_metadata: {
        public_id: 'poster16_de471a33ef',
        resource_type: 'image',
      },
      formats: {
        thumbnail: {
          name: 'thumbnail_poster16.jpg',
          hash: 'thumbnail_poster16_de471a33ef',
          ext: '.jpg',
          mime: 'image/jpeg',
          width: 245,
          height: 138,
          size: 5.24,
          path: null,
          url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614790261/thumbnail_poster16_de471a33ef.jpg',
          provider_metadata: {
            public_id: 'thumbnail_poster16_de471a33ef',
            resource_type: 'image',
          },
        },
        large: {
          name: 'large_poster16.jpg',
          hash: 'large_poster16_de471a33ef',
          ext: '.jpg',
          mime: 'image/jpeg',
          width: 1000,
          height: 563,
          size: 38.89,
          path: null,
          url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614790262/large_poster16_de471a33ef.jpg',
          provider_metadata: {
            public_id: 'large_poster16_de471a33ef',
            resource_type: 'image',
          },
        },
        medium: {
          name: 'medium_poster16.jpg',
          hash: 'medium_poster16_de471a33ef',
          ext: '.jpg',
          mime: 'image/jpeg',
          width: 750,
          height: 422,
          size: 25.93,
          path: null,
          url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614790263/medium_poster16_de471a33ef.jpg',
          provider_metadata: {
            public_id: 'medium_poster16_de471a33ef',
            resource_type: 'image',
          },
        },
        small: {
          name: 'small_poster16.jpg',
          hash: 'small_poster16_de471a33ef',
          ext: '.jpg',
          mime: 'image/jpeg',
          width: 500,
          height: 281,
          size: 14.17,
          path: null,
          url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614790264/small_poster16_de471a33ef.jpg',
          provider_metadata: {
            public_id: 'small_poster16_de471a33ef',
            resource_type: 'image',
          },
        },
      },
      provider: 'cloudinary',
      related: ['603fbe81ad738808d093b969'],
      createdAt: '2021-03-03T16:51:04.380Z',
      updatedAt: '2021-03-03T16:51:13.367Z',
      __v: 0,
      id: '603fbe78ad738808d093b968',
    },
    Date: '2021-02-19',
    title: 'Богдан Кравчук - Качества Апостола Павла',
    id: '603fbe81ad738808d093b969',
  },
  {
    category: 'seminars',
    _id: '603fbeb4ad738808d093b96b',
    url: 'https://youtu.be/2e0xXVFMxdU',
    published_at: '2021-03-03T16:52:14.106Z',
    createdAt: '2021-03-03T16:52:04.999Z',
    updatedAt: '2021-03-14T13:27:26.542Z',
    __v: 0,
    poster: {
      _id: '603f4adb78ae4b4a4303f659',
      name: 'poster11.jpg',
      alternativeText: '',
      caption: '',
      hash: 'poster11_94f0759223',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 111.05,
      width: 1280,
      height: 720,
      url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614760643/poster11_94f0759223.jpg',
      provider_metadata: {
        public_id: 'poster11_94f0759223',
        resource_type: 'image',
      },
      formats: {
        thumbnail: {
          name: 'thumbnail_poster11.jpg',
          hash: 'thumbnail_poster11_94f0759223',
          ext: '.jpg',
          mime: 'image/jpeg',
          width: 245,
          height: 138,
          size: 8.58,
          path: null,
          url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614760653/thumbnail_poster11_94f0759223.jpg',
          provider_metadata: {
            public_id: 'thumbnail_poster11_94f0759223',
            resource_type: 'image',
          },
        },
        large: {
          name: 'large_poster11.jpg',
          hash: 'large_poster11_94f0759223',
          ext: '.jpg',
          mime: 'image/jpeg',
          width: 1000,
          height: 563,
          size: 107.49,
          path: null,
          url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614760662/large_poster11_94f0759223.jpg',
          provider_metadata: {
            public_id: 'large_poster11_94f0759223',
            resource_type: 'image',
          },
        },
        medium: {
          name: 'medium_poster11.jpg',
          hash: 'medium_poster11_94f0759223',
          ext: '.jpg',
          mime: 'image/jpeg',
          width: 750,
          height: 422,
          size: 68.02,
          path: null,
          url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614760664/medium_poster11_94f0759223.jpg',
          provider_metadata: {
            public_id: 'medium_poster11_94f0759223',
            resource_type: 'image',
          },
        },
        small: {
          name: 'small_poster11.jpg',
          hash: 'small_poster11_94f0759223',
          ext: '.jpg',
          mime: 'image/jpeg',
          width: 500,
          height: 281,
          size: 31.93,
          path: null,
          url: 'https://res.cloudinary.com/dslsur5y5/image/upload/v1614760667/small_poster11_94f0759223.jpg',
          provider_metadata: {
            public_id: 'small_poster11_94f0759223',
            resource_type: 'image',
          },
        },
      },
      provider: 'cloudinary',
      related: ['603fbeb4ad738808d093b96b'],
      createdAt: '2021-03-03T08:37:47.734Z',
      updatedAt: '2021-03-03T16:52:05.061Z',
      __v: 0,
      id: '603f4adb78ae4b4a4303f659',
    },
    title: 'Хто такий духовний?',
    Date: '2019-11-10',
    id: '603fbeb4ad738808d093b96b',
  },
];

export const mockData = {
  data: {
    videoGalleries: videos,
  },
};
