import React from 'react';
import clsx from 'clsx';

const MenuButton = ({ handleMenu, showMenu }) => (
  <div className="menu-btn-respons-container2">
    <button type="button" className="fs-primary-nav-trigger" onClick={handleMenu}>
      <span
        className={clsx('fs-menu-icon', { 'is-clicked': showMenu })}
      />
    </button>
  </div>
);

export default MenuButton;
