import React from 'react';
import clsx from 'clsx';

const Social = ({ margin }) => (
  <div className={clsx('footer-2-soc-a', { 'mt-10': margin })}>
    <a
      href="https://www.youtube.com/channel/UCxBWU5akRZcddFYWGCbe-Mg/videos"
      title="YouTube"
      rel="noreferrer"
      target="_blank"
    >
      <i className="fab fa-youtube" />
    </a>
    <a
      href="https://www.instagram.com/gracechurchakkerman"
      title="Instagram"
      rel="noreferrer"
      target="_blank"
    >
      <i className="fab fa-instagram" />
    </a>
  </div>
);

export default Social;
