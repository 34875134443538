export const handleTransition = (showMenu) => {
  if (showMenu) {
    document.body.classList.add('overflow-hidden');
  } else {
    document.body.classList.remove('overflow-hidden');
  }
};

export const handleScrollLock = () => {
  const hasBodyScroll = document.body.classList.contains('overflow-hidden');
  if (hasBodyScroll) {
    document.body.classList.remove('overflow-hidden');
  }
};

export const initializeGallery = (ref) => {
  import('lightgallery.js').then(() => {
    import('lg-video.js').then(() => {
      window.lightGallery(ref);
    });
  });
};
