import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '@/apollo/client';
import MainLayout from '@/layouts/main';
import { init } from '@/utils/sentry';

import * as gtag from '@/lib/gtag';
init();

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/navigation/navigation.scss';
import 'lightgallery.js/dist/css/lightgallery.css';

import '@/styles/globals.css';
import '@/styles/styles.css';
import '@/styles/styles.scss';

export default function App({ Component, pageProps, err }) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const Layout = Component.Layout || MainLayout;

  const router = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;

    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <ApolloProvider client={apolloClient}>
      <Layout>
        <Component {...pageProps} err={err} />
      </Layout>
    </ApolloProvider>
  );
}
