import React, { Fragment, useEffect } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import Link from 'next/link';

const MainMenu = ({ menuItems }) => {
  const router = useRouter();

  useEffect(() => {
    router.prefetch('/contacts');
    router.prefetch('/ministers');
    router.prefetch('/ministry');
    router.prefetch('/about');
    // router.prefetch('/media/songs');
    // router.prefetch('/media/videos');
    // router.prefetch('/media/ministries');
    // router.prefetch('/media/seminars');
    // router.prefetch('/media/scenes');
    // router.prefetch('/media/children');
    // router.prefetch('/media/questions');
    // router.prefetch('/media/testimonies');
  }, []);

  const handleLink = (e, url) => {
    e.preventDefault();
    e.stopPropagation();

    if (url === '/media') {
      return;
    }
    router.push(url);
  };

  return (
    <div className="main-menu-container">
      <div className="container-m-30 clearfix">
        <div id="main-menu">
          <div className="navbar navbar-default" role="navigation">
            <nav className="collapse collapsing navbar-collapse right-1024">
              <ul className="nav navbar-nav">
                {menuItems.map(({ title, url, sub }) => {
                  return (
                    <li
                      key={`parent-${url}`}
                      className={clsx('parent', { parent__sub: sub })}
                    >
                      <a onClick={(e) => handleLink(e, url)}>{title}</a>
                      {sub && (
                        <img
                          className="parent__arrow-down"
                          src="/images/down-arrow.svg"
                        />
                      )}
                      {sub && (
                        <Fragment>
                          <ul className="sub">
                            {sub.map(({ url, title }) => (
                              <li key={`sub-${url}`}>
                                <Link href={url}>{title}</Link>
                              </li>
                            ))}
                          </ul>
                        </Fragment>
                      )}
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;
