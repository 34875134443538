import Link from 'next/link';

const Logo = () => (
  <div className="logo-container-2">
    <div className="logo-2">
      <Link href="/">
        <a className="clearfix">
          <img src="/images/logo.png" className="logo-img" alt="Logo" />
        </a>
      </Link>
    </div>
  </div>
);

export default Logo;
