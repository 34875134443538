import React from 'react';
import Social from '../social';

const followUs = 'Ми в соціальних мережах';

const Footer = () => (
  <footer className="page-section pb-20">
    <div className="container">
      <div className="footer-2-copy-cont">
        <div className="footer-copy">
          <div className="footer-2-copy">&copy; Благодать {new Date().getFullYear()}</div>
        </div>
        <div className="social__warpper">
          <div className="social-label">{followUs}</div>
          <Social />
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
