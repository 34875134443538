import clsx from 'clsx';
import { useState, useEffect, Fragment } from 'react';
import Router from 'next/router';
import { createMenuItems } from '@/data';
import { handleTransition, handleScrollLock } from '@/utils';
import Logo from './logo';
import MenuButton from './menuButton';
import Social from './social';
import MainMenu from './mainMenu';

const Header = () => {
  const [showMenu, setMenu] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  const menuItems = createMenuItems();

  useEffect(() => {
    const handleRouteChange = () => {
      setMenu(false);
    };

    Router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  const handleLink = (url) => {
    handleScrollLock();
    Router.push(url);
  };

  const handleMenu = () => {
    setMenu(!showMenu);
    handleTransition(!showMenu);
  };

  const openSubMenu = (title) => {
    setSubMenu(subMenu === title ? null : title);
  };

  const followUs = 'Ми в соціальних мережах';

  return (
    <header
      id="nav"
      className={clsx('header', 'header-1', 'header-side-menu', 'affix', {
        'affix-fix': showMenu,
      })}
    >
      <div className="header-wrapper">
        <div className="container-m-30 clearfix">
          <div className="logo-row">
            <Logo />
            <MenuButton showMenu={showMenu} handleMenu={handleMenu} />
          </div>
        </div>
        <MainMenu menuItems={menuItems} />
      </div>

      <nav>
        <div
          className={clsx('fs-primary-nav', 'fs-sub-nav', {
            'is-visible': showMenu,
          })}
        >
          <ul className="block-center-xy">
            {menuItems.map(({ title, url, sub }) => {
              return (
                <li
                  key={url}
                  className={clsx({ 'fs-active': title === subMenu })}
                >
                  {sub ? (
                    <Fragment>
                      <button
                        onClick={() => openSubMenu(title)}
                        type="button"
                        className="fs-parent nav-button"
                      >
                        {title}
                        <span className="open-sub" />
                      </button>
                      <ul className="fs-sub">
                        {sub.map(({ url, title }) => {
                          return (
                            <li key={url}>
                              <button
                                className="nav-button nav-button-sub"
                                type="button"
                                onClick={() => {
                                  handleLink(url);
                                  setSubMenu(null);
                                }}
                              >
                                {title}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </Fragment>
                  ) : (
                    <button
                      className="nav-button"
                      type="button"
                      onClick={() => handleLink(url)}
                    >
                      {title}
                    </button>
                  )}
                </li>
              );
            })}

            <div className="social-label">{followUs}</div>
            <Social margin />
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
